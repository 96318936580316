
import React from 'react';
import {Link} from 'react-router-dom';



function Header(){
    return(
        
        <header>
            <div className="header-area ">
                <div id="sticky-header" className="main-header-area">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-3 col-lg-3">
                                <div className="logo-img">
                                    <a href="/"><img src="img/logo.png" alt=""/></a>
                                </div>
                            </div>
                            <div className="col-xl-9 col-lg-9">
                                <div className="menu_wrap d-none d-lg-block">
                                    <div className="menu_wrap_inner d-flex align-items-center justify-content-end">
                                        <div className="main-menu">
                                            <nav>
                                                <ul id="navigation">
                                                <li><a href='/'>Início</a></li>
                                                    <li><Link to='/sobre'>Sobre</Link></li>
                                                    <li><Link to='/atendimento'>Atendimento</Link></li>
                                                    
                                                </ul>
                                                
                                            </nav>
                                           
                                        </div>
                                        <div class="book_room">
                                                <div class="book_btn">
                                                    <Link to='contato'>Contato</Link>
                                                    
                                                </div>
                                            </div>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mobile_menu d-block d-lg-none"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </header>
   
        
        

)
}
export default Header;