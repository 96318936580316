import React from 'react';

function Welcome(){
    return(
        <div id="sobre" className="welcome_clicnic_area">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-6 col-lg-6">
                        <div className="welcome_thumb">
                            <div className="thumb_1">
                                <img src="img/about/1.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                        <div className="welcome_docmed_info">
                            <h3>
                                <span>Dr. Fredy Soares Sousa</span>
                                Ortopedia | Traumatologia | Cirurgia do joelho</h3>
                            <p>Sou natural de Divinópolis/MG, formado em Biologia pela PUC/MG em 2004, em Medicina pela Faculdade de Valença/RJ em 2012, com residência médica em Ortopedia e Traumatologia pelo Hospital Municipal Doutor Carmino Caricchio/SP e especialização em Cirurgia do Joelho no Hospital Madre Teresa em Belo Horizonte/MG.

São mais de 21 anos de estudos, dedicação e aprimoramento para oferecer aos meus pacientes o melhor atendimento, individual e humanizado, sempre focado no bem estar e na eficácia de cada tratamento.</p>
                            <ul>
                                <li> <i className="flaticon-verified"></i> CRMMG 59249 </li>
                                <li> <i className="flaticon-verified"></i> RQE 41252</li>
                                <li> <i className="flaticon-verified"></i> TEOT 15944 </li>
                            </ul>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Welcome;