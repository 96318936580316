import React from 'react';
import Header from '../header/';
import Welcome from '../welcome/';
import Footer from '../footer';
import Rodape from '../rodape';
import Helmet from 'react-helmet';

function SobrePage(){
    return(
        <>
         <Helmet>
                <title>Dr Fredy Soares Sousa - Sobre</title>

                <meta name="description" content="Conheça Dr. Fredy Soares Sousa" />
                <meta name="keywords" content="hospital Santa Mônica, ortopedista,  divinópolis, ortopedista cirurgião, carmo da mata, traumatologia esportiva, traumatologia, cirurgia do joelho, sigmacardio, consultórios especializados" />
            </Helmet>
        <Header/>
        <Welcome/>
        <Rodape/>
        <Footer/>
        </>
    )
}

export default SobrePage;