import React from 'react';


function Insta(){

    return(
        <div className="expert_doctors_area ">
        <div className="container">
            <div className="row justify-content-center ">
                <div className="col-lg-6">
                    <div className="section_title mb-55 text-center">
                        <h3>Acompanhe-me no Instagram</h3>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center justify-content-end">
         
                <div className="col-lg-4 col-md-6">
                    <div className="single_expert">
                        <div id="local1" className="expert_thumb">
                            
                        </div>
                     
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="single_expert">
                        <div id="local2" className="expert_thumb">
                            
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">

                    <div className="single_expert">
                        <div id="local3" className="expert_thumb">
                            
                        </div>
                        
                    </div>
                </div>
                
            </div>
        </div>
        
    </div>
    
   
    )
}
export default Insta;