import React from 'react';
import Header from '../header';
import Rodape from '../rodape';
import Helmet from 'react-helmet';

import Footer from '../footer';

function Contato() {
    return (
        <>
            <Helmet>
                <title>Dr Fredy Soares Sousa - Contato</title>
                <meta name="description" content="Página de Contato Ortopedista dr Fredy Soares Souza" />
                <meta name="keywords" content="consulta ortopedista divinópolis, consulta ortopedista carmo da mata, consulta ortopedista cirurgião divinópolis, consulta ortopedista cirurgião carmo da mata, consulta traumatologia esportiva, consulta cirurgia do joelho, consulta cirurgia no joelho, consulta traumatologia e cirurgia do joelho divinópolis, consulta traumatologia e cirurgia do joelho carmo da mata" />
            </Helmet>
            <Header />
            <div id="contato" class="book_apointment_area">
                <div class="container">
                    <div class="row justify-content-end">
                        <div class="col-lg-7">
                            <div class="popup_box ">
                                <div class="popup_inner">
                                    <h3>
                                        Mande uma<span>Mensagem</span>
                                    </h3>
                                    <form class="form-contact contact_form" action="contact.php" method="post" id="contactForm" novalidate="novalidate">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <textarea class="form-control w-100" name="message" id="message" cols="30" rows="9" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Message'" placeholder=" Mensagem"></textarea>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <input class="form-control valid" name="name" id="name" type="text" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter your name'" placeholder="Nome" />
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <input class="form-control valid" name="email" id="email" type="email" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter email address'" placeholder="Email" />
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <input class="form-control" name="subject" id="subject" type="text" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Subject'" placeholder="Assunto" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group mt-3">
                                            <button type="submit" class="button button-contactForm boxed-btn">Enviar</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Rodape />
            <Footer />
        </>
    )
}
export default Contato;
