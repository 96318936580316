import React from 'react';
import Header from '../header/';
import Slider from '../slider/';
import Welcome from '../welcome/';
import Especialidades from '../especialidades/';
import Footer from '../footer';
import Rodape from '../rodape';
import Insta from '../insta';
import Helmet from 'react-helmet';


function Home() {
    return (
        <>
            <Helmet>
                <title>Dr Fredy Soares Sousa</title>

                <meta name="description" content="Ortopedista dr Fredy Soares Souza, Ortopedia, traumatologia esportiva e cirurgia do joelho. São mais de 21 anos de estudos, dedicação e aprimoramento para oferecer aos meus pacientes o melhor atendimento, individual e humanizado, sempre focado no bem estar e na eficácia de cada tratamento." />
                <meta name="keywords" content="ortopedista divinópolis, ortopedista carmo da mata, ortopedista cirurgião divinópolis, ortopedista cirurgião carmo da mata, traumatologia esportiva, cirurgia do joelho, cirurgia no joelho, traumatologia e cirurgia do joelho divinópolis, traumatologia e cirurgia do joelho carmo da mata" />
            </Helmet>
            <Header />
            <Slider />
            <Welcome />
            <Especialidades />
            <Insta />
            <Rodape />
            <Footer />
        </>
    )
}

export default Home;