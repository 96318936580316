import React from 'react';

function Footer(){
    return(
        <footer className="footer">
            <div className="footer_top">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-md-6 col-lg-4 ">
                            <div className="footer_widget">
                                <div className="footer_logo">
                                    <a href="#">
                                        <img src="img/logo.png" alt=""/>
                                    </a>
                                </div>
                                <p className="address_text">Email e mídias sociais</p>
                                <div className="socail_links">
                                    <ul>
                                        <li>
                                            <a href="mailto:contato@fredysoaressousa.com.br">
                                                <i className="ti-email"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.facebook.com/fredyssousa">
                                                <i className="ti-facebook"></i>
                                            </a>
                                        </li>
                                    
                                        <li>
                                            <a href="https://www.instagram.com/dr.fredyssousa/">
                                                <i className="fa fa-instagram"></i>
                                            </a>
                                        </li>
                                      
                                    </ul>
                                    
                                </div>
                               
                                
                            </div>
                            
                        </div>
                        <div className="col-xl-4 col-md-6 col-lg-4">
                            <div className="footer_widget">
                                <h3 className="footer_title">
                                Sigmacardio
                                </h3>
                                <ul className="meting_time">
                                    <li className="d-flex justify-content-between "><span><i className="fa fa-map-marker"> R. São Paulo, 335 - 3º andar</i></span></li>
                                    <li>
                                        <a href="https://api.whatsapp.com/send/?phone=5537998699327&text&app_absent=0"> <i className="fa fa-whatsapp"></i> +5537998699327 </a>
                                    </li>
                                    <li>
                                        <a href="tel:+553732220300"> <i className="fa fa-phone"></i> +553732220300</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                        <div className="col-xl-4  col-md-6 col-lg-4">
                            <div className="footer_widget">
                                <h3 className="footer_title">
                                    Carmo Clínica
                                </h3>
                                <ul className="meting_time">
                                    <li className="d-flex justify-content-between ">
                                        <span><i className="fa fa-map-marker"> R. Afonso de Bragança, 34</i></span>
                                    </li>
                                    <li>
                                        <a href="tel:+5537999554141"> <i className="fa fa-mobile"></i> +5537999554141</a>
                                    </li>
                                     <li>
                                        <a href="tel:+553733831196"> <i className="fa fa-phone"></i> +553733831196</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="copy-right_text">
                <div className="container">
                    <div className="row">
                        <div className="bordered_1px "></div>
                        <div className="col-xl-12">
                            <p className="copy_right text-center">
                                <p>Copyright &copy;<script>document.write(new Date().getFullYear());</script> Dr. Fredy Soares Sousa - CRMMG 59249 • RQE 41252   |  <i className="ti-heart" aria-hidden="true"></i>  Desenvolvido por <a href="https://www.instagram.com/dot.midias/" target="_blank">Dot Mídias</a></p>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
    </footer>
    )
}
export default Footer;