import React from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

/* PÁGINAS */
import Home from './view/home';
import Contato from './view/contato';
import Sobre from './view/sobrePage';
import Atendimento from './view/atendimentosPage';
import { HelmetProvider } from 'react-helmet-async';


function App() {
  return (
    <HelmetProvider>
      <Switch>
        <Router>
          <Route exact path='/' component={Home} />
          <Route exact path='/contato' component={Contato} />
          <Route exact path='/sobre' component={Sobre} />
          <Route exact path='/atendimento' component={Atendimento} />
        </Router>
      </Switch>
    </HelmetProvider>

  );
}

export default App;

