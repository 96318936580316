import React from 'react';


function Especialidades(){

    return(
        <div id="atendimentos" className="expert_doctors_area overlay">
        <div className="container">
            <div className="row justify-content-center ">
                <div className="col-lg-6">
                    <div className="section_title mb-55 text-center">
                        <h3>Atendimento</h3>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center justify-content-end">
         
                <div className="col-lg-4 col-md-6">
                    <div className="single_expert">
                        
                        <div className="experts_name text-center">
                        <div className="expert_thumb">
                            <img src="img/experts/2.png" alt=""/>
                        </div>
                            <h4>HOSPITAL <br/>SANTA MÔNICA</h4>
                            <span>Divinópolis</span>
                            <li>
                                <span><i className="fa fa-map-marker"> R Pedro F. Amaral, 33</i></span>
                            </li>
                            <div className="social_links">
                                <ul>
                                    <li>
                                        <a href="tel:+553721025600"> <i className="fa fa-phone"></i></a>
                                    </li>
                                
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="single_expert">
                        
                        
                        <div className="experts_name text-center">
                        <div className="expert_thumb">
                            <img src="img/experts/3.png" alt=""/>
                        </div>
                            <h4>CONSULTÓRIOS ESPECIALIZADOS</h4>
                            <span>Carmo da Mata</span>
                            <span><i className="fa fa-map-marker"> R. Afonso de Bragança, 34</i></span>
                            <div className="social_links">
                                <ul>
                                <li>
                                    <a href="tel:+5537999554141"> <i className="fa fa-mobile"></i></a>
                                </li>
                                <li>
                                    <a href="tel:+553733831196"> <i className="fa fa-phone"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/_consultoriosespecializados"> <i className="fa fa-instagram"></i></a>
                                </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">

                    <div className="single_expert">
                        
                        <div className="experts_name text-center">
                        <div className="expert_thumb">
                            <img src="img/experts/1.png" alt=""/>
                        </div>
                            <h4>SIGMACARDIO</h4><br/>
                            <span>Divinópolis</span>
                            <span><i className="fa fa-map-marker"> R. São Paulo, 335 - 3º andar</i></span>
                            <div className="social_links">
                                <ul>
                                    <li>
                                        <a href="https://api.whatsapp.com/send/?phone=5537998699327&text&app_absent=0"> <i className="fa fa-whatsapp"></i></a>
                                    </li>
                                    <li>
                                        <a href="tel:+553732220300"> <i className="fa fa-phone"></i></a>
                                    </li>
                                    <li>
                                    <a href="https://www.instagram.com/clinicasigmacardio"> <i className="fa fa-instagram"></i></a>
                                </li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        
    </div>
    
   
    )
}
export default Especialidades;