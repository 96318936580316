import React from'react';

function Rodape(){
    return(
        <div className="Emergency_contact">
        <div className="Emergency_contact_inner ">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-6">
                        <div className="single_emergency align-items-center d-flex justify-content-start">
                        <span></span>
                        
                        <div className="info icon">
                            <span>Atendimento em:</span>
                            <h3><i class="fa fa-phone"></i> <a href="tel:+5537999554141">Carmo da Mata</a></h3>
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-lg-6">
                        <div className="single_emergency align-items-center d-flex justify-content-center">
                        
                            <div className="info">
                                <span>Atendimento em:</span>
                                <h3><i class="fa fa-phone"></i> <a href="tel:+5537998699327"> Divinópolis</a></h3>
                            </div>
                            
                        </div>
                    </div>
               
                </div>
            </div>
        </div>
    </div>
    )
}
export default Rodape
