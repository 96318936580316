import React from 'react';
import Header from '../header/';
import Especialidades from '../especialidades/';
import Footer from '../footer';
import Rodape from '../rodape';
import Helmet from 'react-helmet';


function Atendimento(){
    return(
        <>
        <Helmet>
                <title>Dr Fredy Soares Sousa - Atendimentos</title>

                <meta name="description" content="Ortopedista dr Fredy Soares Souza, Ortopedia, traumatologia esportiva e cirurgia do joelho. Atende em Divinópolis e Carmo da Mata: Hospital Santa Mônica, Sigmacardio e Consultórios especializados" />
                <meta name="keywords" content="Ortopedista hospital Santa Mônica, ortopedista atende carmo da mata, ortopedista cirurgião atende divinópolis, ortopedista cirurgião atende carmo da mata, traumatologia esportiva atende divinópolis, cirurgia do joelho hospital santa mônica, cirurgia no joelho hospital santa Mônica, traumatologia e cirurgia do joelho santa Mônica divinópolis, traumatologia e cirurgia do joelho sigmacardio carmo da mata" />
            </Helmet>
        <Header/>
        <Especialidades/>
        <Rodape/>
        <Footer/>
        </>
    )
}

export default Atendimento;