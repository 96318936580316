import React from 'react';


function Slider(){

    return(
        <div className="slider_area">
            <div className="slider_active owl-carousel">
                <div className="single_slider d-flex align-items-center slider_bg_1 overlay">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div class="slider_text ">
                                <span>Ortopedia e Traumatologia</span>
                                <h3> <span>Dr Fredy Soares</span> <br/>
                                Traumatologia Esportiva <br/>Cirurgia do Joelho</h3>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )


}
export default Slider;